import { render, staticRenderFns } from "./StoresTeaser.vue?vue&type=template&id=141c686e&"
import script from "./StoresTeaser.vue?vue&type=script&lang=js&"
export * from "./StoresTeaser.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "StoresTeaser.vue"
export default component.exports